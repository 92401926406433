import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import logo from '../assets/rd-logo.png';


function Navbar() {
  return (
    <nav className={styles.nav}>
      <div className={styles.logo}>
        <img src={logo} className={styles.logoImage} alt="RD|EZ logo" />
      </div>
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <Link to="/" className={styles.link}>Home</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/actors" className={styles.link}>Actors</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/stories" className={styles.link}>Stories</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/threads" className={styles.link}>Threads</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/locations" className={styles.link}>Locations</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;