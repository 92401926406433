interface SubsetFormProps {
    inputValue: string;
    handleSubsetterChange: (event: { target: { value: React.SetStateAction<string>; }; }) => void;
    handleSubsetterSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    children?: React.ReactNode;
}

function SubsetForm({ inputValue, handleSubsetterChange, handleSubsetterSubmit, children }: SubsetFormProps) {
    return (
        <>
        <label style={{color: '#ddddff'}}>
            {children}
        </label>
        <input type="text" value={inputValue} onChange={handleSubsetterChange} 
            style={{width: '60%', fontSize: '18px'}}/>
        <form onSubmit={handleSubsetterSubmit}>
            <input type="submit" 
                style={{fontSize: '18px', backgroundColor: '#5384ED', color: 'white', border: 'none', padding: '15px 32px', textAlign: 'center', margin: '4px 5px', cursor: 'pointer'}} 
                value="Wykonaj zapytanie / Execute query" />
        </form>
        </>
    );
}

export default SubsetForm;