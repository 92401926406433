import { useEffect, useState } from "react";
import { Tabs } from 'antd';
import styles from '../styles/GeneralStyles.module.css';

import { ThreadBrief } from "../types/ThreadBrief";
import { MotiveBrief } from "../types/MotiveBrief";
import { API_URL, THREAD_SUBLINK, MOTIVE_SUBLINK } from '../constants';
import SimpleTable from "../components/SimpleTable";


function Threads()
{
    /* Standard loading stuff */
    const [threads, setThreads] = useState<ThreadBrief[]>([]);
    useEffect(() => {
        fetch(`${API_URL}${THREAD_SUBLINK}`)
            .then((response) => response.json())
            .then((data) => setThreads(data))
            .catch((error) => console.error(error))
    }, []);

    const [motives, setMotives] = useState<MotiveBrief[]>([]);
    useEffect(() => {
        fetch(`${API_URL}${MOTIVE_SUBLINK}`)
            .then((response) => response.json())
            .then((data) => setMotives(data))
            .catch((error) => console.error(error))
    }, []);


    /* Display everything */
    return (<div>
        <h1>Threads and Motives</h1>

        <Tabs
        defaultActiveKey="2"
        type="card"
        size="large"
        className={styles.tabContent}
        items={[
            {
              key: '1',
              label: <span>Wyświetl Wątki</span>,
              children: (<>
                <h3>Wątki - logiczne połączenie pomiędzy sesjami</h3>
                <SimpleTable 
                headers={['Link', 'Name', 'StoryCount', 'StartDate', 'EndDate', 'PlayerActors', 'Players', 'Description']}
                data={threads.map(t => ({
                    Link: t.threadUid,
                    Name: t.name,
                    StoryCount: t.storyCount,
                    StartDate: t.startDate,
                    EndDate: t.endDate,
                    PlayerActors: t.playerActors.length,
                    Players: t.players.length,
                    Description: t.shortDesc
                }))}
                linkArea={THREAD_SUBLINK}
            /></>),
            },
            {
              key: '2',
              label: <span>Wyświetl Motywy</span>,
              children: (<>
                <h3>Motywy - niepołączone ze sobą koncepty powtarzające się między sesjami</h3>
            <SimpleTable 
                headers={['Link', 'Name', 'StoryCount', 'StartDate', 'EndDate', 'PlayerActors', 'Players', 'Description']}
                data={motives.map(m => ({
                    Link: m.motiveUid,
                    Name: m.name,
                    StoryCount: m.storyCount,
                    StartDate: m.startDate,
                    EndDate: m.endDate,
                    PlayerActors: m.playerActors.length,
                    Players: m.players.length,
                    Description: m.shortDesc
                }))}
                linkArea={MOTIVE_SUBLINK}
            /></>),
            },
          ]}
        />

    </div>)
}

export default Threads;
