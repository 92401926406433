import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';

import { API_URL, STORY_SUBLINK } from '../constants';
import { StoryDetails } from "../types/StoryDetails";


function SingleStory() {

    const {storyLink} = useParams <{storyLink: string}>()
    const [storyDetails, setStoryDetails] = useState<StoryDetails>();

    useEffect(() => {
        fetch(`${API_URL}${STORY_SUBLINK}/${storyLink}`)
            .then((response) => response.json())
            .then((data) => setStoryDetails(data))
            .catch((error) => console.error(error))
    }, [storyLink])
    
    if(!storyDetails)
    {
        return <h2>Loading... or link is invalid</h2>
    }

    return (
        <div>
            <h2>Tytuł: {storyDetails.storyTitle}</h2>
            <div><b>link / id:</b> {storyDetails.storyUid}</div>
            <div><b>numer bezwzględny:</b> {storyDetails.sequenceNumber}</div>
            <div><b>powiązane wątki:</b> {storyDetails.threads.join(", ")}</div>
            <div><b>powiązane motywy:</b> {storyDetails.motives.join(", ")}</div>
            <div><b>poprzednie opowieści:</b> {storyDetails.previousStories.join(" , ")}</div>
            <div><b>daty: </b>({storyDetails.startDate}) - ({storyDetails.endDate})</div>
            <hr></hr>
            <h2>Obecni</h2>
            <div><b>MG: </b>{storyDetails.gms.join(", ")}</div>
            <div><b>Gracze: </b>{storyDetails.players.join(", ")}</div>
            <div><b>Postacie Graczy: </b>{storyDetails.playerActors.join(", ")}</div>
            <div><b>Wszystkie Postacie: </b>{storyDetails.allActors.join(", ")}</div>
            <hr></hr>
            <h3>Streszczenie:</h3>
            <div>{storyDetails.summary}</div>
            <hr></hr>
            <hr></hr>
            <hr></hr>
            <div><ReactMarkdown>{storyDetails.body}</ReactMarkdown></div>
        </div>
    )
}


export default SingleStory;
