import React from 'react';
import styles from '../styles/AtStoryBlockCollapse.module.css';
import { Collapse } from 'antd';
import { GeneralStoryBlock } from '../types/GeneralStoryBlock';

const { Panel } = Collapse;

type StoryBlockProps = {
    blocks: GeneralStoryBlock[];
};

function GenStoryBlockCollapse(props: StoryBlockProps) {
    return (
        <div className={styles.container}>
        <Collapse bordered={false} expandIconPosition="right" className={styles.collapse} size="large">
            {props.blocks.map((block, index) => (
                <Panel 
                    header={<div>
                        <span className={styles.panelTitleText}>{block.storyTitle}</span>
                        <br />
                        <span className={styles.lighterText}><b>uid:</b> {block.storyUid}  |  <i>numer względny: {props.blocks.length - index}</i> <br /></span>
                        <span className={styles.lighterText}><b>daty:</b> {block.startDate}  -  {block.endDate}<br /></span>
                        <span className={styles.lighterText}><b>obecni:</b> {block.allActorsPresent.join(', ')}</span>
                        </div>} 
                        key={block.storyUid} 
                        className={styles.header}
                    >
                    <div className={styles.content}>
                        <b>Podsumowanie:</b><br />
                        <p>{block.summary}</p>
                    </div>
                </Panel>
            ))}
        </Collapse>
        </div>
    );
}

export default GenStoryBlockCollapse;
