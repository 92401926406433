import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import { API_URL, THREAD_SUBLINK } from "../constants";
import { ThreadDetails } from "../types/ThreadDetails";
import GenStoryBlockCollapse from "../components/GenStoryBlockCollapse"
import { ReactMarkdown } from "react-markdown/lib/react-markdown";



function SingleThread() {

    const {threadLink} = useParams <{threadLink: string}>()
    const [isStoryBlocksVisible, setIsStoryBlocksVisible] = useState(true);
    const [isSpoilersVisible, setIsSpoilersVisible] = useState(false);

    const [details, setDetails] = useState<ThreadDetails>();
    useEffect(() => {
        fetch(`${API_URL}${THREAD_SUBLINK}/${threadLink}`)
            .then((response) => response.json())
            .then((data) => setDetails(data))
            .catch((error) => console.error(error))
    }, [threadLink])

    return (<>
        <hr />
        <h2>Id: {details?.uid} : {details?.name}</h2>
        <hr />
        <h2>Opis:</h2>
        <h3>Krótki:</h3><div>{details?.shortDesc}</div>
        <h3>Pełny:</h3><div>{details?.fullDesc}</div>
        <hr />
        <h2>Opowieści</h2>

        {/* StoryBlocks*/}
        <div className="sb-area">
            <input type="checkbox" id="storyBlockCheckbox" checked={isStoryBlocksVisible} onChange={() => setIsStoryBlocksVisible(!isStoryBlocksVisible)} />
            Czy Powiązane Opowieści mają być widoczne?
            {isStoryBlocksVisible && (<>
            <h3>Powiązane Opowieści - co się działo w kontekście tego Wątku na przestrzeni Opowieści</h3>
            <GenStoryBlockCollapse blocks={details?.storyBlocks  || []} />
            </>)}
        </div>

        {/* Spoilers*/}
        <div className="spoilers-area">
            {details?.spoilers &&(<>
                <input type="checkbox" id="storyBlockCheckbox" checked={isSpoilersVisible} onChange={() => setIsSpoilersVisible(!isSpoilersVisible)} />
                <span style={{color: 'red'}}>!!!Czy Powiązane <b>SPOILERY</b> mają być widoczne? Jeśli nie jesteś MG, rozważ 'nie'!!!</span>
                {isSpoilersVisible && (<>
                    <h3>Powiązane Spoilery</h3>
                    {<ReactMarkdown>{details?.spoilers || ""}</ReactMarkdown>}
                </>)}
            </>)}
        </div>

        <br/><br/><br/>
    </>)
}

export default SingleThread