import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import { API_URL } from '../constants';
import {ACTORS_SUBLINK} from "../constants"

import { ActorDetails } from "../types/ActorDetails";
import ActorGeneralInfo from "./SingleActorSpace/ActorGeneralInfo";
import Flashcards from "./SingleActorSpace/Flashcards";
import Merits from "../components/Merits";
import Progressions from "./SingleActorSpace/Progressions";
import Atars from "../components/Atars";
import ActorThreadStoryBlocks from "./SingleActorSpace/ActorThreadStoryBlocks";


function SingleActor() {

    /* Use URL:
       If you have a Route defined like so: <Route path="/actor/:actorLink" component={SingleActor} />, 
       the actorLink part of the path is dynamic and can be anything. 
       You access the value of this part using the useParams hook.
    */
    const {actorLink} = useParams <{actorLink: string}>()

    /* Fetch the actorDetails */
    const [actorDetails, setActorDetails] = useState<ActorDetails>();
    useEffect(() => {
        fetch(`${API_URL}${ACTORS_SUBLINK}/${actorLink}`)
            .then((response) => response.json())
            .then((data) => setActorDetails(data))
            .catch((error) => console.error(error))
    }, [actorLink])

    /* Default */
    if(!actorDetails || !actorLink)
    {
        return <h2>Loading or link is invalid</h2>
    }

    /* Display */
    return (<div>
        <ActorGeneralInfo details={actorDetails} />
        <hr></hr>
        <Flashcards records={actorDetails.flashcardRecords} />
        <Merits merits={actorDetails.merits} />
        <Progressions progressions={actorDetails.progressions} />
        <Atars relations={actorDetails.actorToActorRelations} visibilityQuery="Czy Relacje z AKTORAMI mają być widoczne?" sectionHeader="ATAR: mapa postać-postacie" />
        <Atars relations={actorDetails.actorToFactionRelations} visibilityQuery="Czy Relacje z FRAKCJAMI mają być widoczne?" sectionHeader="ATFR: mapa postać-frakcje" />
        <ActorThreadStoryBlocks actorLink={actorLink}/>

        <br/><br/><br/>
    </div>)
    
}

export default SingleActor;