import React, {useState, useEffect} from 'react';
import { Tabs } from 'antd';
import ActorsTab from './ActorsSpace/ActorsTab';
import FactionsTab from './ActorsSpace/FactionsTab';
import styles from '../styles/GeneralStyles.module.css'



function Actors()
{
    

    return (
        <div>
            <h1>Aktorzy, Frakcje i inne strony aktywne</h1>
            <Tabs 
                defaultActiveKey="1"
                type="card"
                size="large"
                className={styles.tabContent}
                items={[
                {
                    key: '1',
                    label: <span>Aktorzy - postacie i pojazdy</span>,
                    children: (<>
                        <ActorsTab/>
                    </>)
                }, 
                {
                    key: '2',
                    label: <span>Frakcje - grupy i strony</span>,
                    children: (<>
                        <FactionsTab/>
                    </>)
                }, 
                ]}
            />
                



        </div>
    );
}

export default Actors;