import React, { useState } from 'react';
import GenStoryBlockCollapse from '../../components/GenStoryBlockCollapse';
import { GeneralStoryBlock } from '../../types/GeneralStoryBlock';

function MotiveStoryBlocksArea({ storyBlocks } : {storyBlocks : GeneralStoryBlock[]}) {
    const [isStoryBlocksVisible, setIsStoryBlocksVisible] = useState(true);

    if (!storyBlocks || storyBlocks.length === 0) return <></>;
    return (
        <div className="sb-area">
            <input
                type="checkbox"
                id="storyBlockCheckbox"
                checked={isStoryBlocksVisible}
                onChange={() => setIsStoryBlocksVisible(!isStoryBlocksVisible)}
            />
            Czy Powiązane Opowieści mają być widoczne?
            {isStoryBlocksVisible && (
                <>
                    <h3>Powiązane Opowieści - jakie Opowieści zawierały ten Motyw na przestrzeni czasu</h3>
                    <GenStoryBlockCollapse blocks={storyBlocks} />
                </>
            )}
            <br />
            <br />
        </div>
    );
}

export default MotiveStoryBlocksArea;
