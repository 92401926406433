import { useState } from "react";
import ReactMarkdown from "react-markdown";


function MotiveSpoilers({spoilers} : {spoilers : string})
{
    const [isSpoilersVisible, setIsSpoilersVisible] = useState(false);
    
    if(!spoilers) return <></>
    return (
        <div className="spoilers-area">
            <input 
                type="checkbox" 
                id="storyBlockCheckbox" 
                checked={isSpoilersVisible} 
                onChange={() => setIsSpoilersVisible(!isSpoilersVisible)} 
            />
            <span style={{color: 'red'}}>!!!Czy Powiązane <b>SPOILERY</b> mają być widoczne? Jeśli nie jesteś MG, rozważ 'nie'!!!</span>
            {isSpoilersVisible && (<>
                <h3>Powiązane Spoilery</h3>
                {<ReactMarkdown>{spoilers || ""}</ReactMarkdown>}
            </>)}
        </div>
    )
    
}

export default MotiveSpoilers;