import { useEffect, useState } from "react";
import { ActorThreadStoryBlock } from "../../types/ActorThreadStoryBlock";
import { ACTOR_THREAD_STORY_BLOCKS_SUBLINK, API_URL } from "../../constants";
import AtStoryBlockCollapse from "../../components/AtStoryBlockCollapse";


function ActorThreadStoryBlocks({actorLink} : {actorLink : string})
{
    const [isActorThreadVisible, setIsActorThreadVisible] = useState(true);
    const [actorThreadStoryBlocks, setActorThreadStoryBlocks] = useState<ActorThreadStoryBlock[]>([]);
    useEffect(() => {
        fetch(`${API_URL}${ACTOR_THREAD_STORY_BLOCKS_SUBLINK}/${actorLink}`)
            .then((response) => response.json())
            .then((data) => setActorThreadStoryBlocks(data))
            .catch((error) => console.error(error))
    }, [actorLink])  

    if (!actorLink) return <></>
    if (!actorThreadStoryBlocks || actorThreadStoryBlocks.length === 0) return <></>
    return (
        <div className="atsb-area">
            <input type="checkbox" id="actorThreadCheckbox" checked={isActorThreadVisible} onChange={() => setIsActorThreadVisible(!isActorThreadVisible)} />
            Czy Wątek postaci ma być widoczny?
            {isActorThreadVisible && (<>
            <h3>Wątek postaci - co się z nią działo na przestrzeni lat</h3>
            <AtStoryBlockCollapse blocks={actorThreadStoryBlocks} />
            </>)}
        </div>
    )
}

export default ActorThreadStoryBlocks;
