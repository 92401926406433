import { useState } from "react";
import SimpleTable from "./SimpleTable";
import { STORY_SUBLINK } from "../constants";
import { ActorToActorRelation } from "../types/ActorDetailsSpace/ActorToActorRelation";

interface AtarsProps {
    relations: ActorToActorRelation[];
    visibilityQuery: string;
    sectionHeader: string
}

function Atars({relations, visibilityQuery, sectionHeader} : AtarsProps)
{
    const [isAtarVisible, setIsAtarVisible] = useState(true);

    if (!relations || relations.length === 0) return <></>
    return (
        <div className="relations-area">
            <input type="checkbox" id="atarCheckbox" checked={isAtarVisible} onChange={() => setIsAtarVisible(!isAtarVisible)} />
            {visibilityQuery}
            {isAtarVisible && (<>
            <h3>{sectionHeader}</h3>
            <SimpleTable
                headers={['Z_kim', 'Intensywnosc', 'Kiedy']}
                data={relations.map((r) => ({
                    Z_kim: r.relevantActor,
                    Intensywnosc: r.intensity,
                    Kiedy: r.storyUids.join(', '),
                }))}
                linkArea={STORY_SUBLINK}
            /></>)}
        </div>
    )
}

export default Atars;