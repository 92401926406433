export const API_URL = process.env.REACT_APP_API_URL || 'https://api.riggeddice.pl';
//export const API_URL = 'https://localhost:44368'
//export const API_URL = 'http://51.68.137.64:5425'
export const ACTORS_SUBLINK = '/actors'
export const ACTOR_THREAD_STORY_BLOCKS_SUBLINK = '/actorthreadstoryblocks'
export const STORY_SUBLINK = '/stories'
export const THREAD_SUBLINK = '/threads'
export const MOTIVE_SUBLINK = '/motives'
export const LOCATION_SUBLINK = '/locations'
export const FACTION_SUBLINK = '/factions'
