import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Actors from './pages/Actors'
import Stories from './pages/Stories'
import Threads from './pages/Threads'
import SingleActor from './pages/SingleActor';
import SingleStory from './pages/SingleStory';
import SingleThread from './pages/SingleThread'
import SingleMotive from './pages/SingleMotive'
import Locations from './pages/Locations'
import './App.css';
import SingleFaction from './pages/SingleFaction';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/actors" element={<Actors />} />
          <Route path="/factions" element={<Actors />} />
          <Route path="/actors/:actorLink" element={<SingleActor />} />
          <Route path="/factions/:factionLink" element={<SingleFaction />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/stories/:storyLink" element={<SingleStory />} />
          <Route path="/threads" element={<Threads />} />
          <Route path="/motives" element={<Threads />} />
          <Route path="/threads/:threadLink" element={<SingleThread />} />
          <Route path="/motives/:motiveLink" element={<SingleMotive />} />
          <Route path="/locations" element={<Locations />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
