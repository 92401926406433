import SimpleTable from "../../components/SimpleTable";
import { STORY_SUBLINK } from "../../constants";
import { FlashcardRecord } from "../../types/ActorDetailsSpace/FlashcardRecord";

function Flashcards({records} : {records : FlashcardRecord[]})
{
    if (!records || records.length === 0) return <></>

    return (
        <div className="flashcard-area">
            <h3>Fiszki / flashcardy, rzeczy dopisane w konkretnej Opowieści przez MG</h3>
            <SimpleTable
                headers={['Wydarzenie', 'Link']}
                data={records.map((r) => ({
                    Wydarzenie: r.body,
                    Link: r.originStoryUid
                }))}
                linkArea={STORY_SUBLINK}
            />
        </div>
        )
}

export default Flashcards;