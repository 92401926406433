import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import { API_URL, MOTIVE_SUBLINK, STORY_SUBLINK } from "../constants";
import { MotiveDetails } from "../types/MotiveDetails";
import MotiveAcdeors from "./SingleMotiveSpace/MotiveAcdeors";
import MotiveStoryBlocks from "./SingleMotiveSpace/MotiveStoryBlocks";
import MotiveSpoilers from "./SingleMotiveSpace/MotiveSpoilers";
import MotiveGeneralInfo from "./SingleMotiveSpace/MotiveGeneralInfo";

function SingleMotive() {

    const {motiveLink} = useParams <{motiveLink: string}>()
    const [details, setDetails] = useState<MotiveDetails>();
    
    useEffect(() => {
        fetch(`${API_URL}${MOTIVE_SUBLINK}/${motiveLink}`)
            .then((response) => response.json())
            .then((data) => setDetails(data))
            .catch((error) => console.error(error))
    }, [motiveLink])

    if(!details) return <>Loading / Error</>
    return (<>
        <MotiveGeneralInfo details={details} />
        <MotiveAcdeors motiveAcdeors={details.motiveAcdeors} />
        <MotiveStoryBlocks storyBlocks={details.storyBlocks} />
        <MotiveSpoilers spoilers={details.spoilers}/>
        <br/><br/><br/>
    </>)
}

export default SingleMotive