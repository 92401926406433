import { useState } from "react";
import { ActorProgression } from "../../types/ActorDetailsSpace/ActorProgression";
import SimpleTable from "../../components/SimpleTable";
import { STORY_SUBLINK } from "../../constants";

function Progressions({progressions} : {progressions : ActorProgression[]})
{
    const [isProgressionVisible, setIsProgressionVisible] = useState(true);
    
    if(!progressions || progressions.length === 0) return <></>
    return (
        <div className="progression-area">
            <input type="checkbox" id="progressionCheckbox" checked={isProgressionVisible} onChange={() => setIsProgressionVisible(!isProgressionVisible)} />
            Czy Progresja ma być widoczna?
            {isProgressionVisible && (<>
            <h3>Progresja: co się zmieniło dla postaci podczas Opowieści</h3>
            <SimpleTable
                headers={['Link', 'Dokonanie', 'Data_Konca']}
                data={progressions.map((r) => ({
                    Link: r.originUid,
                    Dokonanie: r.deed,
                    Data_Konca: r.endDate,
                }))}
                linkArea={STORY_SUBLINK}
            /></>)}
        </div>
    )
}

export default Progressions;