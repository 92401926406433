import ReactMarkdown from "react-markdown";
import { ActorDetails } from "../../types/ActorDetails";
import { useState } from "react";


function ActorGeneralInfo({details} : {details : ActorDetails})
{
    const [isBodyVisible, setIsBodyVisible] = useState(false);
    
    return (<>
    {/* Preamble, high level description */}
        <h2>{details.name}: {details.mechver}</h2>
        <hr></hr>
        <p>Należy do: <b>{details.owner}</b></p>
        <p>Podczas Opowieści wystąpiła: <b>{details.merits.length}</b> razy</p>
        <hr></hr>

        {/* This section is responsible for the visibility of the Body */}
        {details.body.length > 0 && (<>
            <label htmlFor="bodyVisibility">        
                <input type="checkbox" id="bodyCheckbox" checked={isBodyVisible} onChange={() => setIsBodyVisible(!isBodyVisible)} />
                Czy Profil (dane z karty postaci) ma być widoczny?
            </label>
            {isBodyVisible && (
                <div><ReactMarkdown>{details.body}</ReactMarkdown></div>
            )}
        </>)}
    
    </>)
}

export default ActorGeneralInfo;