import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { FactionDetails } from "../types/FactionDetails";
import { API_URL, FACTION_SUBLINK } from "../constants";
import Merits from "../components/Merits";
import FactionGeneralInfo from "./SingleFactionSpace/FactionGeneralInfo";
import Atars from "../components/Atars";

function SingleFaction ()
{
    const {factionLink} = useParams <{factionLink: string}>()

    const [factionDetails, setFactionDetails] = useState<FactionDetails>();
    useEffect(() => {
        fetch(`${API_URL}${FACTION_SUBLINK}/${factionLink}`)
            .then((response) => response.json())
            .then((data) => setFactionDetails(data))
            .catch((error) => console.error(error))
    }, [factionLink])

    if(!factionDetails || !factionLink)
    {
        return <h2>Loading or link is invalid</h2>
    }
 
    return (<div>
        <FactionGeneralInfo details={factionDetails} />
        <Merits merits={factionDetails.merits} />
        <Atars relations={factionDetails.ftarRelations} visibilityQuery="Czy Relacje z AKTORAMI mają być widoczne?" sectionHeader="FTAR: mapa frakcja-postacie" />
        <Atars relations={factionDetails.ftfrRelations} visibilityQuery="Czy Relacje z FRAKCJAMI mają być widoczne?" sectionHeader="FTFR: mapa frakcja-frakcje" />

        <br/><br/><br/>
    </div>)
}

export default SingleFaction;
