import { useState } from "react";
import SimpleTable from "./SimpleTable";
import { STORY_SUBLINK } from "../constants";
import { ActorMerit } from "../types/ActorDetailsSpace/ActorMerit";


function Merits({merits} : {merits : ActorMerit[]})
{
    const [isDeedsVisible, setIsDeedsVisible] = useState(true);

    if (!merits || merits.length === 0) return <></>
    return (
        <div className="merit-area">
            <input type="checkbox" id="meritCheckbox" checked={isDeedsVisible} onChange={() => setIsDeedsVisible(!isDeedsVisible)} />
            Czy Dokonania mają być widoczne?
            {isDeedsVisible && (<>
                <h3>Dokonania, najważniejsze rzeczy zrobione przez Aktora podczas Opowieści</h3>
                <SimpleTable
                    headers={['Link', 'Dokonanie', 'Start', 'Koniec']}
                    data={merits.map((r) => ({
                        Link: r.originUid,
                        Dokonanie: r.deed,
                        Start: r.startDate,
                        Koniec: r.endDate
                    }))}
                    linkArea={STORY_SUBLINK}
            /></>)}
        </div>
    )
}

export default Merits;