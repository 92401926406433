import { useEffect, useState } from "react";

import { API_URL, ACTORS_SUBLINK } from '../../constants';
import { ActorBrief } from '../../types/ActorBrief';
import SimpleTable from '../../components/SimpleTable';
import SubsetForm from '../../components/SubsetForm';


function ActorsTab()
{
    /* Standard 'load from endpoint' code */
    const [actors, setActors] = useState<ActorBrief[]>([]);

    useEffect(() => {
        fetch(`${API_URL}${ACTORS_SUBLINK}`)
            .then((response) => response.json())
            .then((data) => setActors(data))
            .catch((error) => console.error(error))
    }, []);


    /* Subsetter / filterer code */
    const [isSubsetterVisible, setIsSubsetterVisible] = useState(false);
    
    const [inputValue, setSubsetterInputValue] = useState("");
    const handleSubsetterChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSubsetterInputValue(event.target.value);
    };
    
    const handleSubsetterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fetch(`${API_URL}${ACTORS_SUBLINK}?filteredBy=${encodeURIComponent(inputValue)}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Log the fetched data to the console
                setActors(data)})
            .catch((error) => console.error(error))
    };

    return (<>
    
            <h2>Aktorzy - postacie i pojazdy</h2>
            {/*Responsible for subsetting and filtering*/}
            <input type="checkbox" id="subsetterCheckbox" checked={isSubsetterVisible} onChange={() => setIsSubsetterVisible(!isSubsetterVisible)}/>
            Czy <b>zaawansowane podzbiorowanie Aktorów</b> powinno być widoczne?<br />
            { isSubsetterVisible && (<>
                <SubsetForm 
                    inputValue={inputValue} 
                    handleSubsetterChange={handleSubsetterChange} 
                    handleSubsetterSubmit={handleSubsetterSubmit} 
                >
                    <br/>To jest zaawansowany system filtrujący, przeładowujący dane z bazy do podzbioru.<br/><br/>
                    Akceptowalne inputy (wyświetl wszystkich Aktorów, którzy...):<br/>
                    <ul>
                        <li>"th: ignis", "threads: ignis" - ...uczestniczyli w dowolnym wątku mającym 'ignis' w nazwie</li>
                        <li>"mt: dreszczowiec", "motives: dreszczowiec" - ...uczestniczyli w Opowieści powiązanej z motywem mającym 'dreszczowiec' w nazwie</li>
                        <li>"sd: 0112-01", "startDate: 0112-01" - ...uczestniczyli w Opowieści o dacie "0112-01" lub późniejszej</li>
                        <li>"ed: 0112-01", "endDate: 0112-01" - ...uczestniczyli w Opowieści o dacie "0112-01" lub wcześniejszej</li>
                        <li>"mi: 10", "minIntensity: 10" - ...mają co najmniej 10 Intensity (uczestniczyli w co najmniej 10 Opowieściach)</li>
                        <li>"l: powiat samszar", "locations: powiat samszar" - ...byli choć raz w lokalizacji "Powiat Samszar"</li>
                        <li>"p: żółw", "players: żółw" - ...byli widziani przez "żółw" jako gracza</li>
                        <li>"gm: żółw" - ...byli uruchomieni przez "żółw" jako MG</li>
                        <li>"a: Karolinus", "actors: Karolinus" - ...spotkali postać imieniem Karolinus</li>
                        <li>średnik (;) jest separatorem dla złożonych zapytań</li>
                    </ul>
                    Przykładowe zapytania:<br/>
                    <ul>
                        <li>"th: serbinius; a:Klaudia" - wyświetl tylko tych Aktorów, których poznała Klaudia w Wątku 'serbinius'</li>
                        <li>"sd: 078; ed:0110-01; a:Martyn Hiwasser" - wyświetl tylko tych Aktorów którzy mają coś wspólnego z Martynem i wystąpili w chronologii 078 - 0110:01</li>
                        <li>"l: Verlenland; a:Martyn Hiwasser" - wyświetl tylko tych Aktorów którzy mają coś wspólnego z Verlenlandem i spotkali Martyna</li>
                    </ul>
                </SubsetForm>
            </>)}

            <SimpleTable
                headers={['Name', 'Link', 'Mechver', 'Intensity']}
                data={actors.map((actor) => ({
                    Name: actor.name,
                    Mechver: actor.mechver,
                    Intensity: actor.intensity,
                    Link: actor.link
                }))}
                linkArea={ACTORS_SUBLINK}
            />

    </>);
}

export default ActorsTab;