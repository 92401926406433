import React, {useState} from "react";
import { Col, Row } from 'antd';
import type { DataNode } from 'antd/es/tree';
import styles from '../styles/Locations.module.css'; 

import LocationDetailsTab from "./LocationSpace/LocationDetailsTab";
import LocationTreeTab from "./LocationSpace/LocationTreeTab";



function Locations() {

    /* Prepare data to display on the right side of the pane */
    const [selectedNode, setSelectedNode] = useState<DataNode | undefined>();
    
    /* Displaying */
    return (<>
      <div className={styles.locationsContainer}>
        <h1>GLORIOUS TREE!</h1>
      </div>
      <div className={styles.treeContainer}>
        <Row gutter={16}>
        <Col span={12} className="gutter-row">
          <LocationTreeTab onSelect={setSelectedNode} />
        </Col>
          <Col span={12} className="gutter-row">
            {selectedNode ? (
              <div>
                <LocationDetailsTab
                selectedKey={selectedNode.key?.toString()}
                />
              </div>
            ) : (
              <h2>This panel will display data on selected LocationRecord</h2>
            )}
          </Col>
        </Row>
      </div>  

    </>);
  }

export default Locations;
