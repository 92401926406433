import React, { useState } from 'react';
import { STORY_SUBLINK } from '../../constants'; // Adjust the import as needed
import SimpleTable from '../../components/SimpleTable';
import { AcdeorPlus } from '../../types/AcdeorPlus';

function MotiveAcdeorArea({ motiveAcdeors } : { motiveAcdeors : AcdeorPlus[] }) {
    const [isAcdeorsVisible, setIsAcdeorsVisible] = useState(true);

    if (!motiveAcdeors || motiveAcdeors.length === 0) return <></>;

    return (
        <div className="acdeor-area">
            <input
                type="checkbox"
                id="acdeorCheckbox"
                checked={isAcdeorsVisible}
                onChange={() => setIsAcdeorsVisible(!isAcdeorsVisible)}
            />
            Czy wykorzystania tego Motywu mają być widoczne?
            {isAcdeorsVisible && (<>
                    <h3>W jaki sposób ten Motyw był wykorzystany przy konstrukcji Opowieści</h3>
                    <SimpleTable
                        headers={['Link', 'Uzycie', 'Data']}
                        data={motiveAcdeors.map((r) => ({
                            Link: r.originUid,
                            Uzycie: r.deed,
                            Data: r.endDate,
                        }))}
                        linkArea={STORY_SUBLINK}
                    />
                </>)}
            <br />
            <br />
        </div>
    );
}

export default MotiveAcdeorArea;
