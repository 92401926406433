import React, {useState, useEffect} from 'react';
import { API_URL, STORY_SUBLINK } from '../constants';
import SimpleTable from '../components/SimpleTable';
import SubsetForm from '../components/SubsetForm';
import { StoryBrief } from '../types/StoryBrief';


function Stories()
{

    /* Standard 'load from endpoint' code */
    const [stories, setStories] = useState<StoryBrief[]>([]);
    useEffect(() => {
        fetch(`${API_URL}${STORY_SUBLINK}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Log the fetched data to the console
                setStories(data)})
            .catch((error) => console.error(error))
    }, []);

    /* Subsetter / filterer code */
    const [isSubsetterVisible, setIsSubsetterVisible] = useState(false);
    
    const [inputValue, setSubsetterInputValue] = useState("");
    const handleSubsetterChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSubsetterInputValue(event.target.value);
    };
    
    const handleSubsetterSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fetch(`${API_URL}${STORY_SUBLINK}?filteredBy=${encodeURIComponent(inputValue)}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Log the fetched data to the console
                setStories(data)})
            .catch((error) => console.error(error))
    };

    /* Visualise EVERYTHING */
    return (
        <div>
            <h1>Stories</h1>
            
            {/*Responsible for subsetting and filtering*/}            
            <input type="checkbox" id="subsetterCheckbox" checked={isSubsetterVisible} onChange={() => setIsSubsetterVisible(!isSubsetterVisible)}/>
            Czy <b>zaawansowane podzbiorowanie Opowieści</b> powinno być widoczne?<br />
            { isSubsetterVisible && (<>
                <SubsetForm 
                    inputValue={inputValue} 
                    handleSubsetterChange={handleSubsetterChange} 
                    handleSubsetterSubmit={handleSubsetterSubmit} 
                >
                    <br/>To jest zaawansowany system filtrujący, przeładowujący dane z bazy do podzbioru.<br/><br/>
                    Akceptowalne inputy (wyświetl wszystkie Opowieści...):<br/>
                    <ul>
                        <li>"a: Karolinus", "actors: Karolinus" - ...gdzie występował Aktor imieniem Karolinus</li>
                        <li>"th: ignis", "threads: ignis" - ...gdzie były Wątki zawierające 'ignis' w nazwie</li>
                        <li>"mt: dreszczowiec", "motives: dreszczowiec" - ...gdzie były Motywy zawierające 'dreszczowiec' w nazwie</li>
                        <li>"sd: 0112-01", "startDate: 0112-01" - ...zaczynające się datą "0112-01"</li>
                        <li>"ed: 0112-01", "endDate: 0112-01" - ...kończące się datą "0112-01"</li>
                        <li>"l: powiat samszar", "locations: powiat samszar" - ...będące w lokalizacji "Powiat Samszar"</li>
                        <li>"p: żółw", "players: żółw" - ...gdzie "żółw" był graczem</li>
                        <li>"gm: żółw" - ...gdzie "żółw" był MG</li>
                        <li>średnik (;) jest separatorem dla złożonych zapytań</li>
                    </ul>
                    Przykładowe zapytania:<br/>
                    <ul>
                        <li>"th: serbinius; a:Klaudia" - wyświetl tylko te Opowieści, które mają Wątek 'serbinius' i występuje Klaudia jako Aktor</li>
                        <li>"sd: 078; ed:0110-01; a:Martyn Hiwasser" - wyświetl tylko te Opowieści gdzie występuje Martyn zanim spotkał Ariannę</li>
                    </ul>
                </SubsetForm>
            </>)}

            {/*Display a SimpleTable */}
            <SimpleTable
                headers={['Title', 'Link', 'StartDate', 'EndDate', 'Threads', 'Motives', 'Players']}
                data={stories.map((story) => ({
                    Link: story.storyUid,
                    Title: story.title,
                    StartDate: story.startDate,
                    EndDate: story.endDate,
                    Threads: story.threads,
                    Motives: story.motives,
                    Players: story.players
                }))}
                linkArea={STORY_SUBLINK}
            />
        </div>
    );
    
}

export default Stories;