import { useState, useEffect } from "react";

import {SingleLocationDetails} from '../../types/SingleLocationDetails'
import { API_URL, LOCATION_SUBLINK, STORY_SUBLINK } from "../../constants";

import SimpleTable from "../../components/SimpleTable";

interface LocationDetailsTabProps {
    selectedKey: string | undefined;
}

function LocationDetailsTab({selectedKey} : LocationDetailsTabProps){

    /* If it ever changes, reload LocationDetails*/
    const [locationDetails, setLocationDetails] = useState<SingleLocationDetails>();

    useEffect(() => {
        fetch(`${API_URL}${LOCATION_SUBLINK}/${selectedKey}`)
            .then((response => response.json()))
            .then((data) => setLocationDetails(data))
            .catch((error) => console.error(error))
    }, [selectedKey])

    return (<>
        <h2>Dane lokalizacji</h2>
        <h3>Dane ogólne</h3>
        <ul style={{fontSize: '110%', lineHeight: '1.5'}}>
            <li><b>Nazwa: </b>{locationDetails?.name}</li>
            <li><b>Ścieżka: </b>{locationDetails?.path}</li>
            <li><b>Intensywność: </b>{locationDetails?.storyBlocks.length}</li>
            <li><b>Pierwsze pojawienie: </b>{locationDetails?.firstStartDate}</li>
            <li><b>Ostatnie pojawienie: </b>{locationDetails?.lastEndDate}</li>
            <li><b>Pierwsza Opowieść: </b>{locationDetails?.relatedStories[locationDetails?.relatedStories.length - 1]}</li>
            <li><b>Ostatnia Opowieść: </b>{locationDetails?.relatedStories[0]}</li>
            <li><b>Ilość powiązanych Opowieści: </b>{locationDetails?.relatedStories.length}</li>
            <li><b>Ilość powiązanych Aktorów: </b>{locationDetails?.allActorsPresent.length}</li>
            <li><b>Ilość powiązanych Wątków: </b>{locationDetails?.relatedThreads.length}</li>
            <li><b>Ilość znaczących dedykowanych Wydarzeń: </b>{locationDetails?.usefulLocationRecords.length}</li>
        </ul>
        <h3>Znaczące wydarzenia</h3>

        {locationDetails && 
        <SimpleTable 
            headers = {['Link', 'Wydarzenie', 'EndDate']}
            data = {locationDetails!.usefulLocationRecords.map(d => ({
                Link: d.originatingStory,
                Wydarzenie: d.event,
                EndDate: d.endDate
            }))}
            linkArea={STORY_SUBLINK}
        />}

        <h3>Powiązane byty</h3>
        <b>Aktorzy: </b> <br />{locationDetails?.allActorsPresent.join(", ")}<br /><br />
        <b>Opowieści: </b> <br />{locationDetails?.relatedStories.join(", ")}<br /><br /> 
        <b>Wątki: </b> <br />{locationDetails?.relatedThreads.join(", ")}<br /><br />

        </>);
}

export default LocationDetailsTab;
