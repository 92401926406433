import { useEffect, useState } from "react";
import { Tree } from "antd";
import type { DataNode } from 'antd/es/tree';

import styles from '../../styles/Locations.module.css'; 

import { API_URL } from '../../constants';
import { LOCATION_SUBLINK } from "../../constants";
import { LocationTreeNode } from "../../types/LocationTreeNode";

interface LocationTreeTabProps {
  onSelect: (selectedNode: DataNode) => void;
}

const convertApiLocationsToDisplay = (apiTree: LocationTreeNode | LocationTreeNode[]): DataNode[] => {
  if (Array.isArray(apiTree)) {
    return apiTree.map(convertApiLocationsToDisplay).flat();
  } else {
    return [{
      key: apiTree.path,
      title: apiTree.title,
      children: convertApiLocationsToDisplay(apiTree.children),
    }]
  }
}

const extractKeysFromTree = (tree: DataNode[]): string[] => {
  let keys: string[] = [];
  for (let node of tree) {
    keys.push(node.key.toString());
    if (node.children) {
      keys = [...keys, ...extractKeysFromTree(node.children)];
    }
  }
  return keys;
}

function LocationTreeTab({ onSelect }: LocationTreeTabProps) {

  const [locationTree, setLocationTree] = useState<DataNode[] | undefined>();
  const [expandedKeys, setExpandedKeys] = useState(['Świat|']);

  useEffect(() => {
    fetch(`${API_URL}${LOCATION_SUBLINK}`)
      .then((response) => response.json())
      .then((data: LocationTreeNode | LocationTreeNode[]) => {
        const locationTree = convertApiLocationsToDisplay(data);
        setLocationTree(locationTree)
        const locationKeys = extractKeysFromTree(locationTree)
        setExpandedKeys(locationKeys);
      })
      .catch((error) => console.error(error))
  }, [])

  const onExpand = (expandedKeys: React.Key[]) => {
    setExpandedKeys(expandedKeys as string[]);
  };

  return (
    <Tree 
      className={styles.actualTree}
      treeData={locationTree} 
      expandedKeys={expandedKeys}
      onExpand={onExpand }
      onSelect={(selectedKeys, info) => {
        onSelect(info.node);
      }}
    />
  );
}



export default LocationTreeTab;