import React, {useState, useMemo} from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/SimpleTable.module.css';

interface Props{
    headers: string[]
    data: any[]
    linkArea: string
}

type sortDirection = 'asc' | 'desc';

/**
 * You need to MANUALLY make sure the data corresponds to headers. Otherwise it will explode.
 */
function SimpleTable({ headers, data, linkArea }: Props) {
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<sortDirection>('asc');
    const [filterValue, setFilterValue] = useState<string>('');
  
    const toggleSort = (column: string) => {
      if (column === sortColumn) {              // if we select the column we previously sorted by, reverse order of sorting
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {                                  // otherwise set which column we sort by and sort ascended
        setSortColumn(column);
        setSortDirection('asc');
      }
    };
  
    const dataToDisplay = useMemo(() => {
      let filteredData = data;
  
      if (filterValue !== '') {
        filteredData = data.filter((row) =>
          headers.some(
            (header) =>
              String(row[header]).toLowerCase().indexOf(filterValue.toLowerCase()) > -1
          )
        );
      }
  
      if (sortColumn === null) {
        return filteredData;
      }
  
      return filteredData.slice()       // this here is for sorting, earlier we were filtering
        .sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];
            if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDirection === 'asc'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        });
    }, [data, filterValue, headers, sortColumn, sortDirection]);
  

    return (
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th colSpan={headers.length}>
                <input
                  type="text"
                  placeholder="Filter table"
                  value={filterValue}
                  onChange={(event) => setFilterValue(event.target.value)}
                />
              </th>
            </tr>
            <tr>
              {headers.map((header) => (
                <th key={header}>
                  <button onClick={() => toggleSort(header)}>
                    {header} {sortColumn === header ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataToDisplay.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>
                      {header.toLowerCase() === 'link' ? (
                      <td key={header}>
                          <Link to={`${linkArea}/${row[header]}`}>{row[header]}</Link>
                      </td>
                      ) : (
                      <td key={header}>{row[header]}</td>
                      )}                
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  export default SimpleTable;
