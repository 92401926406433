import ReactMarkdown from "react-markdown";
import { FactionDetails } from "../../types/FactionDetails";
import { useState } from "react";

function FactionGeneralInfo({details} : {details : FactionDetails})
{
    const [isBodyVisible, setIsBodyVisible] = useState(false);

    if(!details) return <></>
    if(!details.body) return <h2>{details.name}</h2>
    return (<>
        <h2>{details.name}</h2>

        <label htmlFor="bodyVisibility">        
            <input type="checkbox" id="bodyCheckbox" checked={isBodyVisible} onChange={() => setIsBodyVisible(!isBodyVisible)} />
            Czy Profil ma być widoczny?
        </label>
        <hr></hr>
        {isBodyVisible && (<>
            <div><ReactMarkdown>{details.body}</ReactMarkdown></div>
            <br />
            <hr />
        </>)}
    </>)
}

export default FactionGeneralInfo;