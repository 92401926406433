import { useEffect, useState } from "react";

import { API_URL, FACTION_SUBLINK } from '../../constants';
import { FactionBrief } from '../../types/FactionBrief';
import SimpleTable from '../../components/SimpleTable';



function FactionsTab()
{
    /* Standard 'load from endpoint' code */
    const [factions, setFactions] = useState<FactionBrief[]>([]);

    useEffect(() => {
        fetch(`${API_URL}${FACTION_SUBLINK}`)
            .then((response) => response.json())
            .then((data) => setFactions(data))
            .catch((error) => console.error(error))
    }, []);

    return (<>
    
        <h2>Frakcje - grupy i kolektywy</h2>
        <SimpleTable
            headers={['Name', 'Link', 'Intensity', 'ShortDesc']}
            data={factions.map((faction) => ({
                Name: faction.name,
                Link: faction.link,
                Intensity: faction.intensity,
                ShortDesc: faction.shortDesc
            }))}
            linkArea={FACTION_SUBLINK}
        />

    </>);
}

export default FactionsTab;